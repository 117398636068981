
import { defineAsyncComponent, defineComponent } from 'vue'
import router from '@/router'
import { OrderActions } from '@/store/modules/orders/actions'
import { VehiclesActions } from '@/store/modules/vehicles/actions'

export default defineComponent({
  components: {
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue'))
  },
  data () {
    return {
      tableFields: [
        { id: 'date', name: 'Created Date', width: '10%', sortable: true, currentSort: false, sort: null, sortName: 'order_created_at' },
        { id: 'username', name: 'Username', width: '16%', sortable: false, currentSort: false, sort: null, sortName: 'username' },
        { id: 'driverId', name: 'Driver Id', width: '16%', sortable: false, currentSort: false, sort: null, sortName: 'driver_id' },
        { id: 'orderStatus', name: 'Order Status', width: '16%', sortable: false, currentSort: false, sort: null, sortName: 'order_status' },
        { id: 'ordersCount', name: 'Orders Count', width: '16%', sortable: true, currentSort: false, sort: null, sortName: 'orders_count' }
      ],
      showFilterModal: false,
      filters: [
        { name: this.$t('filters.month'), model: 'dates', type: 'date', calendarType: 'month' },
        {
          name: this.$t('filters.orderStatus'),
          model: 'status',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'orderStatus',
          type: 'radio',
          data: []
        },
        { name: this.$t('filters.ordersCount'), model: 'ordersCount', type: 'number' },
        {
          name: this.$t('filters.vehicleType'),
          model: 'vehicleTypeId',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'vehicleTypes',
          type: 'radio',
          data: []
        }
      ],
      searchValue: null as any,
      selectedReport: this.reportType
    }
  },
  methods: {
    onExpandPanelClick (eventName: string): void {
      if (eventName === 'orderStatus') {
        this.$store.dispatch(`orders/${OrderActions.FETCH_ORDER_STATUSES}`)
      }
      if (eventName === 'vehicleTypes') {
        this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLE_TYPES]}`)
      }
    },
    menuEvent (e: any): void {
      console.log(e)
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    }
  },
  watch: {
    searchValue (val) {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, username: this.searchValue } })
    },
    vehicleTypes: {
      immediate: true,
      handler (val) {
        if (!val.length) return
        const target = this.filters[3]
        target.dataRequested = true
        target.data = val
        target.dataLoading = false
      }
    },
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
        if (val.query.orderId) {
          this.searchValue = val.query.orderId
        }
      }
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    },
    statuses (val: any) {
      if (!val.length) return
      const filterItems = val.map((el: any) => {
        return {
          id: el.id,
          label: el.value
        }
      })
      const target = this.filters[1]
      target.dataRequested = true
      target.data = filterItems
      target.dataLoading = false
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    vehicleTypes (): any[] {
      return this.$store.getters['vehicles/getVehicleTypes']
        .map((el: any) => {
          return {
            id: el.id,
            label: el.value
          }
        })
    },
    reports () {
      return this.$store.getters['reports/getProductivityReports']
    },
    exportReports () {
      return this.$store.getters['reports/getProductivityExportReports']
    },
    pagination () {
      return this.$store.getters['reports/getProductivityPagination']
    },
    loading () {
      return this.$store.getters['reports/getLoading']
    },
    statuses (): any {
      return this.$store.getters['orders/getOrderStatuses']
    }
  }
})
